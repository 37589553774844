<template>
  <section id="benefits" class="banner_benefits_component" :style="'background:'+$props.background+';color:'+$props.textColor+';margin:auto;'">
    <b-container style="max-width: 1280px;">
      <h2 v-html="$props.title" class="display-4 text-center"></h2>
      <b-row class="" style="width:auto;">
        <b-col v-for="item in $props.items" :key="item.id" class="p-3">
          <b-card
            style="max-width: 30rem;border-radius: 1.0em;"
            class="m-2 border-0"
          >
            <b-card-text >
              <div class="text-center">
                <b-img :src="item.icon" height="80rem" alt="" class="mb-3" ></b-img>
              </div>
              <div class="title" v-html="item.title"></div>
              <div class="subtitle" v-html="item.description"></div>
            </b-card-text>
        </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'BannerBenefitsComponent',
  props: {
    title: String,
    items: Array,
    background: String,
    textColor: String
  }
}
</script>
<style lang="scss" scoped>
  .banner_benefits_component {
    padding-bottom:1rem;
    .title{
      font-size: 1.5rem;
      height: 2.5em;
      line-height:1.8rem;
      text-align: center;
      color:#333333;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }
    .subtitle{
      font-size: 1rem;
      line-height:1.4rem;
      text-align: center;
      font-weight: normal;
      color:#646464;
    }
  }
</style>
